<template>
  <div class="RepreneurRegister" >
    <div class="container-fluid">

      <b-modal size="xl" ref="repreneurSaveAccountModal" hide-footer>
        <template v-slot:modal-title>
          <strong> Sauvegarder vos informations et créer votre compte</strong>
        </template>
        <repreneurRegistrationSaveAccount
          :hasIdentifyCompany="hasIdentifyCompany"
        />
      </b-modal>

      <h1 class="gavc-h1 text-center">Je reprends une entreprise</h1>
      <div class="row mt-3">
        <div class="col-10 offset-1">
          <stepBar
            :labels="labels"
            :currentStep="Number(repreneurRegistrationStep)"
            :totalSteps="labels.length"
          />
        </div>
      </div>
    </div>
    <div class="container">
      <div class="mt-4">
        <repreneurRegistrationProject
          v-if="Number(repreneurRegistrationStep) === 1"
          v-on:nextStep="nextStep"
        />

        <!--<repreneurRegistrationEntreprise
          v-if="Number(repreneurRegistrationStep) === 2 && !repreneurIsPersonnePhysique"
          v-on:nextStep="nextStep"
          v-on:previousStep="previousStep"
          v-on:saveAccount="saveAccount"
        />-->

        <repreneurRegistrationEntrepriseCible
          v-if="boolHasIdentifyCompany && Number(repreneurRegistrationStep) === 2"
          v-on:nextStep="nextStep"
          v-on:previousStep="previousStep"
        />

        <repreneurRegistrationValidation
          v-if="(Number(repreneurRegistrationStep) === 2 && !boolHasIdentifyCompany) || (Number(repreneurRegistrationStep) === 3 && boolHasIdentifyCompany)"
          v-on:nextStep="nextStep"
          v-on:previousStep="previousStep"
        />
      </div>

    </div>
  </div>
</template>

<script>
import { createHelpers } from 'vuex-map-fields';

const repreneurRegistrationProject = () => import('@/components/registration/repreneur/repreneurRegistrationProject')
const repreneurRegistrationEntrepriseCible = () => import('@/components/registration/repreneur/repreneurRegistrationEntrepriseCible')
const repreneurRegistrationValidation = () => import('@/components/registration/repreneur/repreneurRegistrationValidation')
const repreneurRegistrationSaveAccount = () => import('@/components/registration/repreneur/repreneurRegistrationSaveAccount')
const stepBar = () => import('@/components/layout/stepBar')

const { mapFields } = createHelpers({
  mutationType: 'updateFieldWithLocalStorage',
});


export default {
  name: 'RepreneurRegister',
  components: {
    repreneurRegistrationProject,
    repreneurRegistrationEntrepriseCible,
    repreneurRegistrationValidation,
    repreneurRegistrationSaveAccount,
    stepBar
  },
  computed: {
    ...mapFields([
      'repreneur.hasIdentifyCompany',
      'repreneurRegistrationStep',
      'repreneur.repreneurIsPersonnePhysique',
    ]),
    boolHasIdentifyCompany () {
      if (String(this.hasIdentifyCompany) === 'true') {
        return true
      }
      return false
    },
    labels () {
      if (String(this.hasIdentifyCompany) === 'true') {
        return ['Votre projet de reprise', 'Entreprise à reprendre', 'Validation']
      }
      else {
        return ['Votre projet de reprise', 'Validation']
      }
    }
  },

  methods: {
    nextStep () {
      this.repreneurRegistrationStep ++
    },
    previousStep () {
      this.repreneurRegistrationStep --
    },
    saveAccount () {
      this.$refs.repreneurSaveAccountModal.show()
    }
  }
}
</script>
